.single-tile-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    display: flex;
    justify-content: center;
  }

  .turf-name {
    padding: 0px 5px;
    margin-bottom: 0px;
  }
}

.flex-cards {
  background-color: rgba(57, 16, 133, 0.05);
  height: 100%;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid rgba(57, 16, 133, 0.1);
}

.turf-title {
  color: #722ed1 !important;
  font-size: 3rem !important;
}

.share-btn {
  margin-left: 10px;
  margin-top: 10px;
}

.detail-menu-row {
  align-items: center;
}

.back-button {
  position: fixed;
  top: 5rem;
  left: 1rem;
  z-index: 10;
}

.header-row {
  width: 100%;
}

.banner-image {
  object-fit: cover;
  filter: brightness(0.75);
  height: 500px !important;

  @media screen and (max-width: 768px) {
    height: 300px !important;
  }
}

.banner-content-parent {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.banner-content-col2 {
  display: flex;

  div {
    width: 300px;

    div {
      height: fit-content;
    }

    button {
      margin: 5px 0px;
    }
  }
}

.banner-detail-row {
  background-color: transparent;
  max-width: 900px;
  padding: 20px;
  margin: auto;
}

.map-container {
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .banner-content-col2 {
    position: fixed;
    display: none;
    justify-content: end;
    height: 20px;
    bottom: 50px;
  }

  .book-now-btn-container {
    position: sticky;
    bottom: 0px;
    background-color: white;
    box-shadow: 5px 0px 1rem rgba(0, 0, 0, 0.15);
  }

  .turf-title {
    font-size: 2rem !important;
  }

  .ant-space-item>h4 {
    font-size: 1rem !important;
  }
}